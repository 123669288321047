<script>
// Backend data for images: https://github.com/limbo-works/VIVE-Website-Umbraco-10/issues/54
// Everything needs to be passed in where the image is used, no fancy-schmancy "pass in the object"
// stuff, because then things get too complicated.
export default {
	name: 'BaseImg',

	functional: true,

	props: {
		alt: {
			type: String,
			default: '',
		},

		// Note that credits will add a span-wrapper around the image.
		credits: {
			type: String,
			default: '',
		},
		creditsId: {
			type: String,
			default: '',
		},

		loadColor: {
			type: String,
			default: '#F1F1F1',
		},

		// When you need to target the wrapper that is added when credits are present.
		wrapperClassNames: {
			type: [String, Object, Array],
			default: '',
		},
	},

	render(h, ctx) {
		const { props } = ctx;
		const {
			staticClass = '',
			class: dynamicClass = null,
			staticStyle = '',
			style: dynamicStyle = null,
			attrs = {},
			on = {},
			nativeOn = {},
		} = ctx.data;

		const { fit, width, loadColor } = { ...props, ...attrs };
		let placeholderStyles = null;
		if (loadColor) {
			let { height, ratio } = { ...props, ...attrs };
			if (ratio) {
				height = Math.round(width / ratio);
			} else {
				ratio = `${width} / ${height}`;
			}

			const placeholderSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><rect width="100%" height="100%" fill="${loadColor}" /></svg>`;
			const backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(
				placeholderSvg
			)}")`;

			placeholderStyles =
				fit === 'contain'
					? {
							'--background-image': backgroundImage,
							'--background-size': fit,
							'--background-position': '50% 50%',
					  }
					: {
							'--background-color': loadColor,
							'--background-size': '100% 100%',
					  };
		}

		const bindings = {
			class: ['c-base-img', staticClass, dynamicClass].filter(Boolean),
			style: [placeholderStyles, staticStyle, dynamicStyle].filter(
				Boolean
			),
			attrs: {
				...attrs,
				alt: props.alt || '',
			},
			on,
			nativeOn,
		};

		const elm = <NuxtPictureExt {...bindings} />;

		if (props.credits) {
			return (
				<span
					class={[
						'c-base-img__wrapper',
						props.wrapperClassNames,
					].filter(Boolean)}
				>
					{elm}
					<span
						id={props.creditsId || null}
						class="c-base-img__credits"
					>
						{props.credits}
					</span>
				</span>
			);
		}

		return elm;
	},
};
</script>

<style lang="postcss">
.c-base-img {
	position: relative;
}
.c-base-img img,
.c-base-img:before {
	transition: opacity 0.2s ease;
}

.c-base-img:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: 0;

	/* placeholder */
	background-color: var(--background-color);
	background-image: var(--background-image);
	background-size: var(--background-size);
	background-position: var(--background-position);
	background-repeat: no-repeat;
}

.c-base-img.c-nuxt-picture-ext--is-loading img {
	opacity: 0;
}
.c-base-img.c-nuxt-picture-ext--is-loading:before {
	opacity: 1;
}

.c-base-img__wrapper {
	/* wow it's rare that both width and height should be auto */
	@apply relative block w-auto h-auto;
}
.c-base-img__credits {
	@apply absolute inline-block right-0 bottom-0 max-w-full w-max py-6 px-12;
	@apply text-list-title-xs bg-black bg-opacity-50 text-white;
}
</style>
